import styled from "styled-components";
import TabItem from "./TabItem";

const Container = styled.div`
  display: flex;
  gap: 10px;
`;

const TabContainer = styled.div`
  width: 80px;
  right: 10px;
  position: fixed;
  border-left: 1px solid #babae6;
`;

const Body = styled.div`
  margin-left: 10px;
  width: calc(100% - 100px);
`;

export { TabItem };

export default function VerticalTab({ currentTab, children }) {
  return (
    <Container>
      <Body>{currentTab}</Body>
      <TabContainer>{children}</TabContainer>
    </Container>
  );
}
