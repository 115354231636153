import { useState } from "react";
import DashboardTemplate from "../../components/Templates/DashboardTemplate";
import PrimaryButton from "../../components/Form/PrimaryButton";
import Form from "../../components/Form";
import ExamTypeInput from "../../components/Form/ExamTypeInput";
import insert from "../../axe-api/insert";
import { useNavigate } from "react-router-dom";

const BASIC_RULES = {
  exam_type: "required",
};

export default function AddExamPage() {
  const navigate = useNavigate();
  const [isValid, setValid] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [form, setForm] = useState({
    exam_type: null,
  });

  const handleSubmit = async () => {
    const data = {
      exam_type: form.exam_type,
    };

    setLoading(true);
    await insert("group-exams", data);
    setLoading(false);
    navigate("/exam-list");
  };

  return (
    <DashboardTemplate title="Toplu Sınav Ekle">
      <Form
        onSubmit={handleSubmit}
        rules={BASIC_RULES}
        state={form}
        setState={setForm}
        setValid={setValid}
      >
        <ExamTypeInput name="exam_type" title="Sınav Türü" isRequired />
        <PrimaryButton
          type="submit"
          isLoading={isLoading}
          disabled={isValid === false}
        >
          Kaydet
        </PrimaryButton>
      </Form>
    </DashboardTemplate>
  );
}
