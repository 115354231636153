import BounceLoader from "react-spinners/BounceLoader";
import styled from "styled-components";

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: calc(100vh - 150px);
`;

export default function Loader() {
  return (
    <Container>
      <BounceLoader color="#5f5fcf" loading={true} size={50} />
    </Container>
  );
}
