import AnswerBox from "../../../components/AnswerBox";

export default function BoxDetail({ exam, answers, currentTab, refetch }) {
  if (!answers || !currentTab || !exam) {
    return <div></div>;
  }

  const currentAnswers = answers[currentTab];
  return (
    <AnswerBox
      index={parseInt(currentTab.replace("box", ""))}
      exam={exam}
      items={currentAnswers}
      refetch={refetch}
    />
  );
}
