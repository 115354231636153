import styled from "styled-components";

const Container = styled.div`
  padding: 15px;
`;

const Box = styled.div`
  border: 1px solid #f1f1f1;
  padding: 15px;
  border-radius: 5px;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
`;

export default function AuthTemplate({ children }) {
  return (
    <Container>
      <Box>{children}</Box>
    </Container>
  );
}
