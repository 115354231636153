import axios from "axios";
import { useDispatch } from "react-redux";
import { useState } from "react";
import PrimaryButton from "../../../components/Form/PrimaryButton";
import DeleteButton from "../../../components/Form/DeleteButton";
import patch from "../../../axe-api/patch";
import { addQuestion } from "../../../store/actions/question";
import { getFileSelector } from "../../../helpers/loader";

export default function AnswerKey({ exam, refetch }) {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);

  const handleFileSelection = async (files) => {
    try {
      setLoading(true);
      const [file] = files;
      const formData = new FormData();
      formData.append("file", file);
      const { data } = await axios.post(
        "https://optical.matmaxkids.com/read",
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      await patch(`group-exams/${exam.id}`, {
        answer_key: JSON.stringify(data.answers),
      });
      await refetch();
      setLoading(false);
    } catch (error) {
      setLoading(false);
      throw error;
    }
  };

  const handleClick = (event) => {
    event.preventDefault();
    const selector = getFileSelector();
    document.body.appendChild(selector);
    selector.onchange = () => {
      let files = Array.from(selector.files);
      document.body.removeChild(selector);
      handleFileSelection(files);
    };
    selector.click();
  };

  const handleDelete = async () => {
    setLoading(true);
    await patch(`group-exams/${exam.id}`, {
      answer_key: null,
      box_1: null,
      box_2: null,
      box_3: null,
      box_4: null,
    });
    await refetch();
    setLoading(false);
  };

  const askDeleteQuestion = () => {
    dispatch(
      addQuestion(
        "Emin misin?",
        "Cevap anahtarını silmek istediğinden emin misin?",
        handleDelete
      )
    );
  };

  if (exam.answer_key === null) {
    return (
      <PrimaryButton onClick={handleClick} isLoading={loading}>
        Cevap Anahtarını Yükle
      </PrimaryButton>
    );
  }

  return (
    <div>
      <DeleteButton onClick={askDeleteQuestion} isLoading={loading}>
        Cevap Anahtarını Sil
      </DeleteButton>
    </div>
  );
}
