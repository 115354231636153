import styled from "styled-components";
import axios from "axios";
import dayjs from "dayjs";
import * as Sentry from "@sentry/react";
import { toast } from "react-toastify";
import { toFilledNumber } from "../../../helpers/shared";
import PrimaryButton from "../../../components/PrimaryButton";
import DataRow, { DataComment } from "../../../components/DataRow";
import { useNavigate } from "react-router-dom";

export const EXAM_TYPE_LABELS = {
  QUIZ: "Quiz",
  KONU_DENEMESI: "Konu Denemesi",
  TYT: "TYT Denemesi",
  AYT_SAYISAL: "AYT - Sayısal",
  AYT_EA: "AYT - Eşit Ağırlık",
  AYT_SOZEL: "AYT - Sözel",
  AYT_DIL: "AYT - Dil",
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin: 10px;
`;

export default function ExamTab({ exam, student, pack }) {
  const navigate = useNavigate();

  const handleSave = async () => {
    try {
      await axios.post("save-optical", pack);
      toast.success("Sonuçlar başarıyla kaydedildi.");
      navigate("/");
    } catch (error) {
      Sentry.captureException(error);
      toast.error("Bir hata meydana geldi.");
    }
  };

  return (
    <Container>
      <DataRow title="Sınav Türü">
        {toFilledNumber(exam.id)} -{EXAM_TYPE_LABELS[exam.exam_type]}
      </DataRow>
      <DataRow title="Sınav Tarihi">
        {dayjs(exam.created_at).format("DD-MM-YYYY")}
      </DataRow>
      <DataRow title="Öğrenci">
        {toFilledNumber(student.id)} - {student.name}
      </DataRow>
      {pack.exam_lessons.map((examLesson, index) => (
        <DataRow key={index} title={exam[`box${index + 1}`].name}>
          {examLesson.net_value}
          <DataComment>(%{examLesson.success_rate})</DataComment>
        </DataRow>
      ))}
      <DataRow title="Toplam Net">
        {pack.total_net_value}
        <DataComment>(%{pack.total_success_rate})</DataComment>
      </DataRow>
      <PrimaryButton onClick={handleSave}>Kaydet</PrimaryButton>
    </Container>
  );
}
