import styled from "styled-components";

const DataContainer = styled.div``;

const DataTitle = styled.div`
  color: #888;
  font-size: 13px;
  font-weight: 500;
  margin-bottom: 2px;
`;

const DataBody = styled.div`
  color: #555;
  font-size: 14px;
  font-weight: 700;
`;

export const DataComment = styled.small`
  padding-left: 5px;
  color: #999;
`;

export default function DataRow({ title, children }) {
  return (
    <DataContainer>
      <DataTitle>{title}</DataTitle>
      <DataBody>{children}</DataBody>
    </DataContainer>
  );
}
