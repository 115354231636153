import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import AuthContext from "./AuthContext";
import axios from "axios";
import logoImage from "./logo.jpeg";
import styled from "styled-components";

const DEFAULT_DATA = {
  isAuth: false,
  token: null,
  id: null,
  name: null,
  email: null,
};

const LogoContainer = styled.div`
  height: 100%;
  position: absolute;
  width: 100%;
  display: flex;
  align-items: center;
`;

const Logo = styled.img`
  margin: 0 auto;
  width: 200px;
  height: 200px;
`;

export default function AuthProvider({ children }) {
  const navigate = useNavigate();
  const [isReady, setReady] = useState(false);
  const [data, setData] = useState(DEFAULT_DATA);

  const login = async ({ token }) => {
    axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
    try {
      const response = await axios.get("/me");
      setData({
        ...data,
        ...response.data,
        isAuth: true,
        token,
      });
      localStorage.setItem("token", token);
      setReady(true);
    } catch (error) {
      if (error?.response?.status === 401) {
        localStorage.removeItem("token");
        setReady(true);
      } else {
        throw error;
      }
    }
  };

  useEffect(() => {
    const { hostname } = window.location;
    if (hostname === "localhost") {
      axios.defaults.baseURL = "http://localhost:4000/api";
    } else {
      axios.defaults.baseURL = "https://api.matmaxkids.com/api";
    }
    axios.defaults.headers.post["Content-Type"] =
      "application/x-www-form-urlencoded";

    const token = localStorage.getItem("token");
    if (token) {
      login({ token });
    } else {
      navigate("/login");
      setReady(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const logout = () => {
    localStorage.removeItem("token");
    axios.defaults.headers.common["Authorization"] = null;
    setData(DEFAULT_DATA);
    navigate("/");
  };

  return (
    <AuthContext.Provider
      value={{
        ...data,
        login,
        logout,
      }}
    >
      {isReady && children}
      {!isReady && (
        <LogoContainer>
          <Logo src={logoImage} />
        </LogoContainer>
      )}
    </AuthContext.Provider>
  );
}
