import axios from "axios";

export const getFileSelector = () => {
  const fileSelector = document.createElement("input");
  fileSelector.setAttribute("type", "file");
  fileSelector.setAttribute("accept", "image/png, image/gif, image/jpeg");
  // fileSelector.setAttribute("style", "display: none;");
  return fileSelector;
};

const handleFileSelection = async ({ files, setLoading, navigate }) => {
  try {
    setLoading(true);
    const [file] = files;
    const formData = new FormData();
    formData.append("file", file);
    const { data } = await axios.post(
      "https://optical.matmaxkids.com/read",
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );
    localStorage.setItem("reader-data", JSON.stringify(data));
    const { examNumber, studentNumber } = data;
    navigate(`/read/${studentNumber}/${examNumber}`);
  } catch (error) {
    setLoading(false);
    throw error;
  }
};

const onFileSelection = ({ event, setLoading, navigate }) => {
  event.preventDefault();
  const selector = getFileSelector();
  document.body.appendChild(selector);
  selector.onchange = () => {
    let files = Array.from(selector.files);
    document.body.removeChild(selector);
    handleFileSelection({ files, setLoading, navigate });
  };
  selector.click();
};

const useLoader = ({ setLoading, navigate }) => {
  return (event) => {
    onFileSelection({ event, setLoading, navigate });
  };
};

export default useLoader;
