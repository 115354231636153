import styled from "styled-components";

const Container = styled.button`
  height: 80px;
  margin-bottom: 10px;
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 12px;
  font-weight: 500;
  border: 1px solid #babae6;
  display: block;
  width: 100%;
  background-color: white;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 15px -3px,
    rgba(0, 0, 0, 0.05) 0px 4px 6px -2px;
  transition: background-color 100ms linear;
  transition: border 100ms linear;
  color: #666;
  overflow: hidden;

  &.active {
    background-color: rgb(95, 95, 207);
    border: 1px solid rgb(95, 95, 207);
    color: white;
  }
`;

export default function TabItem({ active = false, name, children, onClick }) {
  return (
    <Container
      type="button"
      className={active ? "active" : ""}
      onClick={(event) => onClick({ event, name })}
    >
      {children}
    </Container>
  );
}
