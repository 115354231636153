import React from "react";
import styled from "styled-components";
import PrimaryButton from "../Form/PrimaryButton";
import DeleteButton from "../Form/DeleteButton";
import { useSelector, useDispatch } from "react-redux";
import { removeQuestion } from "../../store/actions/question";

const Container = styled.div`
  width: calc(100% - 30px);
  height: fit-content;
  background-color: white;
  border-radius: 4px;
  border: 1px solid #f1f1f1;

  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  margin-top: 100px;
`;

const GrayBox = styled.div`
  background: black;
  opacity: 0.5;
  position: fixed;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
`;

const Title = styled.p`
  margin: 0px;
  font-size: 18px;
  color: #333;
  font-weight: 600;
  border-bottom: 1px solid #f1f1f1;
  padding: 20px 10px;
`;

const Content = styled.p`
  color: #333;
  text-align: center;
  font-weight: 400;
  padding: 30px;
`;

const ActionBox = styled.div`
  border-top: 1px solid #f1f1f1;
  padding: 20px 10px;
`;

export default function QuestionContainer() {
  const dispatch = useDispatch();
  const { title, question, callback } = useSelector((state) => state.question);

  if (title === null) {
    return "";
  }

  const handleOk = () => {
    callback();
    dispatch(removeQuestion());
  };

  const handleCancel = () => {
    dispatch(removeQuestion());
  };

  return (
    <>
      <GrayBox />
      <Container>
        <Title>{title}</Title>
        <Content>{question}</Content>
        <ActionBox>
          <PrimaryButton
            type="button"
            style={{ marginRight: "10px" }}
            onClick={handleOk}
          >
            Evet
          </PrimaryButton>
          <DeleteButton type="button" onClick={handleCancel}>
            Hayır
          </DeleteButton>
        </ActionBox>
      </Container>
    </>
  );
}
