import { useContext } from "react";
import { toast } from "react-toastify";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import AuthTemplate from "../../components/Templates/AuthTemplate";
import TextInput from "../../components/Form/TextInput";
import PrimaryButton from "../../components/Form/PrimaryButton";
import Form from "../../components/Form";
import { useState } from "react";
import AuthContext from "../../providers/AuthContext";

const Title = styled.h1`
  text-align: center;
  color: #414141;
  margin: 0px;
  font-size: 24px;
  padding-bottom: 14px;
  border-bottom: 1px solid #f1f1f1;
  margin-bottom: 30px;
`;

const FormBody = styled(Form)``;

const LoginButton = styled(PrimaryButton)`
  margin-top: 20px;
  width: 100%;
`;

const RULES = {
  email: "required|email",
  password: "required|min:6",
};

export default function LoginPage() {
  const navigate = useNavigate();
  const [isLoading, setLoading] = useState(false);
  const authContent = useContext(AuthContext);
  const [isValid, setValid] = useState(false);
  const [form, setForm] = useState({
    email: "",
    password: "",
  });

  const handleLogin = async () => {
    setLoading(true);
    try {
      const { data } = await axios.post("/login", form);
      await authContent.login({ token: data.token });
      navigate("/");
    } catch (response) {
      toast("Bir hata meydana geldi.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <AuthTemplate>
      <Title>Optik Okuyucu</Title>
      <FormBody
        onSubmit={handleLogin}
        rules={RULES}
        state={form}
        setState={setForm}
        setValid={setValid}
      >
        <TextInput
          name="email"
          title="E-posta adresi"
          placeholder="matmax@mail.com"
        />
        <TextInput
          name="password"
          type="password"
          title="Parola"
          placeholder="Çok gizli parola"
        />
        <LoginButton
          type="submit"
          onClick={handleLogin}
          isLoading={isLoading}
          disabled={!isValid}
        >
          Oturumu Aç
        </LoginButton>
      </FormBody>
    </AuthTemplate>
  );
}
