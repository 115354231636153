import styled from "styled-components";

const Container = styled.button`
  aspect-ratio: 1 / 1;
  width: 100%;
  background-color: white;
  border: 1px solid #f1f1f1;
  border-radius: 5px;
  font-weight: 700;
  text-transform: uppercase;
  color: #333;
  box-shadow: rgba(0, 0, 0, 0.05) 0px 1px 2px 0px;
  padding: 15px;
`;

const Icon = styled.div`
  color: rgb(95, 95, 207);
  padding-bottom: 20px;
`;

export default function Box({ icon, children, ...props }) {
  return (
    <Container type="button" {...props}>
      <Icon>{icon}</Icon>
      {children}
    </Container>
  );
}
