import styled from "styled-components";

const Container = styled.button`
  width: 100%;
  margin-bottom: 15px;
  border: 1px solid #f1f1f1;
  background-color: white;
  padding: 10px;
  border-radius: 4px;
  min-height: 60px;
  text-align: left;
  font-weight: 500;
  box-shadow: rgba(27, 31, 35, 0.04) 0px 1px 0px,
    rgba(255, 255, 255, 0.25) 0px 1px 0px inset;
  cursor: pointer;
`;

const Title = styled.div`
  font-weight: 600;
  padding-bottom: 10px;
  color: rgba(0, 0, 0, 0.8);
`;

const Description = styled.div`
  color: #999;
`;

export default function Line({ title, description, ...props }) {
  return (
    <Container type="button" {...props}>
      <Title>{title}</Title>
      <Description>{description}</Description>
    </Container>
  );
}
