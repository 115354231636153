import styled from "styled-components";

const StyledButton = styled.button`
  background-color: #5f5fcf;
  color: white;
  font-size: 16px;
  line-height: 1.4;
  font-weight: 300px;
  border: none;
  padding: 14px 32px;
  border-radius: 3px;
  cursor: pointer;

  &:hover {
    background-color: #6a6acc;
  }

  &:disabled {
    background-color: #8989cc;
  }

  &:focus {
    outline: none;
  }
`;

export default function PrimaryButton({
  type = "button",
  isLoading = false,
  children,
  disabled,
  ...props
}) {
  return (
    <StyledButton type={type} {...props} disabled={disabled || isLoading}>
      {isLoading && "loading..."}
      {!isLoading && children}
    </StyledButton>
  );
}
