import styled from "styled-components";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Loader from "../Loader";
import FooterLink from "../FooterLink";
import { HomeIcon, BarcodeIcon, FolderIcon, UserIcon } from "../Icons";

const Container = styled.div``;

const Header = styled.div`
  position: fixed;
  top: 0px;
  height: 60px;
  width: 100%;
  overflow: hidden;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 6px -1px,
    rgba(0, 0, 0, 0.06) 0px 2px 4px -1px;
  background-color: white;
  display: flex;
  align-items: center;
  padding: 0px 15px;
  font-size: 18px;
  font-weight: 500;
`;

const Body = styled.div`
  padding: 75px 15px;

  &.no-padding {
    padding: 75px 0px;
  }
`;

const Footer = styled.div`
  position: fixed;
  bottom: 0px;
  height: 60px;
  width: 100%;
  overflow: hidden;
  background-color: rgb(95, 95, 207);
  color: white;
  display: flex;
  align-items: center;
`;

const HomeLink = styled(Link)`
  color: rgb(95, 95, 207);
  text-decoration: none;
  font-weight: 700;
  cursor: pointer;
`;

const SubTitle = styled.small`
  text-transform: uppercase;
  font-size: 12px;
  font-weight: 600;
`;

export default function DashboardTemplate({
  loading = false,
  title,
  children,
  bodyClass = "",
  startFileSelection,
}) {
  const location = useLocation();
  const navigate = useNavigate();
  const { pathname } = location;

  return (
    <Container>
      <Header>
        <div>
          <HomeLink to="/">MATMAX</HomeLink> <SubTitle>{title}</SubTitle>
        </div>
      </Header>
      <Body className={bodyClass}>
        {loading && <Loader />}
        {!loading && <>{children}</>}
      </Body>
      <Footer>
        <FooterLink active={pathname === "/"} onClick={() => navigate("/")}>
          <HomeIcon />
        </FooterLink>
        <FooterLink
          active={pathname.includes("/read/")}
          onClick={startFileSelection}
        >
          <BarcodeIcon />
        </FooterLink>
        <FooterLink
          active={pathname === "/exam-list" || pathname.includes("/exam/")}
          onClick={() => navigate("/exam-list")}
        >
          <FolderIcon />
        </FooterLink>
        <FooterLink
          active={pathname === "/account"}
          onClick={() => navigate("/account")}
        >
          <UserIcon />
        </FooterLink>
      </Footer>
    </Container>
  );
}
