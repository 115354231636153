import styled from "styled-components";

const Container = styled.button`
  width: 25%;
  height: 100%;
  border: none;
  background: transparent;
  color: white;
  opacity: 0.15;
  font-weight: 500;
  font-size: 16px;
  cursor: pointer;

  &.active {
    opacity: 1;
  }

  &:focus {
    outline: none;
  }
`;

export default function FooterLink({ children, active = false, onClick }) {
  return (
    <Container
      type="button"
      onClick={onClick}
      className={active ? "active" : ""}
    >
      {children}
    </Container>
  );
}
