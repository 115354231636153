import { useEffect, useState } from "react";
import dayjs from "dayjs";
import DashboardTemplate from "../../components/Templates/DashboardTemplate";
import API from "../../axe-api/api";
import Line from "../../components/Line";
import { useNavigate } from "react-router-dom";
import useLoader from "../../helpers/loader";

export default function ExamList() {
  const [exams, setExams] = useState(null);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const loader = useLoader({ setLoading, navigate });

  const fetchExams = async () => {
    const data = await API("group-exams")
      .sort("-created_at")
      .fetch({ page: 1, per_page: 100 });
    setExams(data);
  };

  const handleExamClick = (exam) => {
    navigate(`/exam/${exam.id}`);
  };

  useEffect(() => {
    fetchExams();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <DashboardTemplate
      loading={exams === null || loading}
      title="Toplu Sınavlar"
      startFileSelection={loader}
    >
      {exams && (
        <>
          {exams.data.map((exam, index) => (
            <Line
              onClick={() => handleExamClick(exam)}
              key={index}
              title={"#" + exam.id + " " + exam.exam_type}
              description={dayjs(exam.created_at).format("DD-MM-YYYY")}
            />
          ))}
        </>
      )}
    </DashboardTemplate>
  );
}
