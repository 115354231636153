import styled from "styled-components";
import dayjs from "dayjs";
import DataRow from "../../../components/DataRow";
import { toFilledNumber } from "../../../helpers/shared";
import AnswerKey from "./AnswerKey";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 100%;
`;

const EXAM_TYPE_LABELS = {
  QUIZ: "Quiz",
  KONU_DENEMESI: "Konu Denemesi",
  TYT: "TYT Denemesi",
  AYT_SAYISAL: "AYT - Sayısal",
  AYT_EA: "AYT - Eşit Ağırlık",
  AYT_SOZEL: "AYT - Sözel",
  AYT_DIL: "AYT - Dil",
};

export default function BasicInfo({ exam, refetch }) {
  return (
    <Container>
      <DataRow title="Sınav Türü">
        {toFilledNumber(exam.id)} -{EXAM_TYPE_LABELS[exam.exam_type]}
      </DataRow>
      <DataRow title="Sınav Tarihi">
        {dayjs(exam.created_at).format("DD-MM-YYYY")}
      </DataRow>
      <AnswerKey exam={exam} refetch={refetch} />
    </Container>
  );
}
