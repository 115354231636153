import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { Provider } from "react-redux";
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import AuthProvider from "./providers/AuthProvider";
import {
  LoginPage,
  AccountPage,
  DashboardPage,
  ReadPage,
  ExamListPage,
  ExamDetailPage,
  AddExamPage,
} from "./pages";
import store from "./store";
import QuestionContainer from "./components/QuestionContainer";

if (process.env.NODE_ENV === "production") {
  Sentry.init({
    dsn: "https://898ee42df365439aa62f3896983354e9@o948871.ingest.sentry.io/5897829",
    integrations: [new Integrations.BrowserTracing()],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,
  });
}

function App() {
  return (
    <Provider store={store}>
      <BrowserRouter>
        <AuthProvider>
          <Routes>
            <Route path="/" element={<DashboardPage />} />
            <Route path="/account" element={<AccountPage />} />
            <Route path="/exam/:id" element={<ExamDetailPage />} />
            <Route path="/exam-list" element={<ExamListPage />} />
            <Route path="/add-exam" element={<AddExamPage />} />
            <Route
              path="/read/:studentNumber/:examNumber"
              element={<ReadPage />}
            />
            <Route path="/login" element={<LoginPage />} />
          </Routes>
        </AuthProvider>
      </BrowserRouter>
      <QuestionContainer />
      <ToastContainer />
    </Provider>
  );
}

export default App;
