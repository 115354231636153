import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { useContext } from "react";
import AuthContext from "../../providers/AuthContext";
import DashboardTemplate from "../../components/Templates/DashboardTemplate";
import { useEffect, useState } from "react";
import useLoader from "../../helpers/loader";
import API from "../../axe-api/api";
import DataRow from "../../components/DataRow";
import PrimaryButton from "../../components/PrimaryButton";

const Container = styled.div`
  display: flex;
  gap: 10px;
  flex-direction: column;
  margin-bottom: 40px;
`;

export default function AccountPage() {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const loader = useLoader({ setLoading, navigate });
  const [user, setUser] = useState(null);
  const authContent = useContext(AuthContext);

  const getMe = async () => {
    const me = await API("me").fetch({});
    setUser(me);
  };

  const handleLogout = () => {
    authContent.logout();
    navigate("/login");
  };

  useEffect(() => {
    getMe();
  }, []);

  return (
    <DashboardTemplate
      title="Hesabım"
      loading={loading || user === null}
      startFileSelection={loader}
    >
      {user && (
        <Container>
          <DataRow title="İsim">{user.name}</DataRow>
          <DataRow title="E-posta">{user.email}</DataRow>
        </Container>
      )}
      <PrimaryButton style={{ width: "100%" }} onClick={handleLogout}>
        Oturumu Kapat
      </PrimaryButton>
    </DashboardTemplate>
  );
}
