import AnswerBox from "../../../components/AnswerBox";

export default function LessonTab({ exam, pack, answers, currentTab }) {
  const currentAnswers = answers[currentTab];
  return (
    <AnswerBox
      index={parseInt(currentTab.replace("box", ""))}
      exam={exam}
      items={currentAnswers}
      isEditable={false}
    />
  );
}
