import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import DashboardTemplate from "../../components/Templates/DashboardTemplate";
import VerticalTab, { TabItem } from "../../components/VerticalTab";
import BasicInfo from "./Tabs/BasicInfo";
import BoxDetail from "./Tabs/BoxDetail";
import API from "../../axe-api/api";
import useLoader from "../../helpers/loader";

const PAGE_TABS = [
  {
    name: "basic",
    title: "Genel",
  },
];

export default function AddDetailPage() {
  const { id } = useParams();
  const [exam, setExam] = useState(null);
  const [answers, setAnswers] = useState(null);
  const [tabs, setTabs] = useState([...PAGE_TABS]);
  const [currentTab, setCurrentTab] = useState("basic");
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const loader = useLoader({ setLoading, navigate });

  const getCurrentTabComponent = () => {
    switch (currentTab) {
      case "basic":
        return BasicInfo;
      case "box1":
      case "box2":
      case "box3":
      case "box4":
        return BoxDetail;
      default:
        return BasicInfo;
    }
  };

  const getExam = async (id) => {
    const exam = await API("group-exams").with("box1,box2,box3,box4").find(id);
    setExam(exam);
  };

  useEffect(() => {
    getExam(id);
  }, [id]);

  useEffect(() => {
    if (exam?.answer_key) {
      const answers = JSON.parse(exam.answer_key);
      setTabs([
        ...PAGE_TABS,
        ...Object.keys(answers).map((key) => {
          return {
            name: key,
            title: key,
          };
        }),
      ]);
      setAnswers(answers);
    } else {
      setTabs([...PAGE_TABS]);
    }
  }, [exam?.answer_key]);

  const CurrentTabComponent = getCurrentTabComponent();

  return (
    <DashboardTemplate
      title="Sınav Detayı"
      loading={exam === null || loading}
      startFileSelection={loader}
    >
      <VerticalTab
        currentTab={
          <CurrentTabComponent
            currentTab={currentTab}
            exam={exam}
            answers={answers}
            refetch={() => getExam(id)}
          />
        }
      >
        {tabs.map((tab, index) => (
          <TabItem
            key={index}
            name={tab.name}
            active={currentTab === tab.name}
            onClick={({ name }) => setCurrentTab(name)}
          >
            {tab.title}
          </TabItem>
        ))}
      </VerticalTab>
    </DashboardTemplate>
  );
}
