import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import DashboardTemplate from "../../components/Templates/DashboardTemplate";
import Box from "../../components/Box";
import { BarcodeIcon, ExamPlusIcon, FolderIcon } from "../../components/Icons";
import { useState } from "react";
import useLoader from "../../helpers/loader";

const BoxContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 15px;
  align-self: center;
`;

export default function Dashboard() {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const loader = useLoader({ setLoading, navigate });

  return (
    <DashboardTemplate
      title="Optik Okuyucu"
      loading={loading}
      startFileSelection={loader}
    >
      <BoxContainer>
        <Box icon={<BarcodeIcon />} onClick={loader}>
          Optik Okuyucu
        </Box>
        <Box icon={<FolderIcon />} onClick={() => navigate("/exam-list")}>
          Toplu Sınavlar
        </Box>
        <Box icon={<ExamPlusIcon />} onClick={() => navigate("/add-exam")}>
          Toplu Sınav Ekle
        </Box>
      </BoxContainer>
    </DashboardTemplate>
  );
}
