import { useEffect, useState } from "react";
import API from "../../axe-api/api";
import styled from "styled-components";
import DashboardTemplate from "../../components/Templates/DashboardTemplate";
import VerticalTab, { TabItem } from "../../components/VerticalTab";
import ExamTab from "./Tabs/ExamTab";
import LessonTab from "./Tabs/LessonTab";

const ErrorBox = styled.div`
  margin: 10px;
  border: 1px solid red;
  padding: 15px;
  border-radius: 5px;
  color: #464646;
`;

const VERTICAL_TABS = [{ name: "exam", title: "Özet" }];

const getCalculations = (exam, data) => {
  const answers = exam.answer_key ? JSON.parse(exam.answer_key) : null;
  const exam_lessons = [];
  for (let index = 1; index <= 4; index++) {
    const currentBox = `box${index}`;
    if (exam[currentBox]) {
      const correctAnswers = answers[currentBox];
      const studentAnswers = data.answers[currentBox];
      const pack = {
        lesson_id: exam[currentBox].id,
        question_count: correctAnswers.length,
        correct_answers: 0,
        incorrect_answers: 0,
        net_value: 0,
        success_rate: 0,
      };

      correctAnswers.forEach((correctAnswer, index) => {
        const studentAnswer = studentAnswers[index];
        if (studentAnswer) {
          if (studentAnswer === correctAnswer) {
            pack.correct_answers++;
          } else {
            pack.incorrect_answers++;
          }
        }
      });

      pack.net_value = pack.correct_answers - pack.incorrect_answers / 4;
      pack.success_rate = parseFloat(
        ((100 * pack.net_value) / pack.question_count).toFixed(2)
      );

      exam_lessons.push(pack);
    }
  }

  const totalQuestion = exam_lessons.reduce(
    (total, examLesson) => total + examLesson.question_count,
    0
  );
  const total_net_value = exam_lessons.reduce(
    (total, examLesson) => total + examLesson.net_value,
    0
  );
  return {
    exam_group_id: exam.id,
    student_id: data.studentNumber,
    exam_type: exam.exam_type,
    exam_lessons,
    total_net_value,
    total_success_rate: parseFloat(
      ((100 * total_net_value) / totalQuestion).toFixed(2)
    ),
  };
};

export default function Read() {
  const [currentTab, setCurrentTab] = useState("exam");
  const [errors, setErrors] = useState([]);
  const [tabs, setTabs] = useState([]);
  const [exam, setExam] = useState(null);
  const [student, setStudent] = useState(null);
  const [pack, setPack] = useState(null);
  const data = JSON.parse(localStorage.getItem("reader-data") || {});
  const { answers, examNumber, studentNumber } = data;

  const fetchExam = async () => {
    try {
      const exam = await API("group-exams")
        .with("box1,box2,box3,box4")
        .find(examNumber);
      setExam(exam);
      setTabs([
        ...VERTICAL_TABS,
        ...["box1", "box2", "box3", "box4"]
          .filter((key) => exam[key])
          .map((key) => {
            return {
              name: key,
              title: exam[key].name,
            };
          }),
      ]);
    } catch (error) {
      if (error?.response?.status === 404) {
        setErrors([...errors, "Sınav no geçersiz."]);
      } else {
        setErrors([...errors, "Sınav bilgileri alınırken bir hata oluştu"]);
      }
    }
  };

  const fetchStudent = async () => {
    try {
      const student = await API("students")
        .with("school{name}")
        .find(studentNumber);
      setStudent(student);
    } catch (error) {
      if (error?.response?.status === 404) {
        setErrors([...errors, "Öğrenci no geçersiz."]);
      } else {
        setErrors([...errors, "Öğrenci bilgileri alınırken bir hata oluştu."]);
      }
    }
  };

  useEffect(() => {
    fetchExam();
    // eslint-disable-next-line
  }, [examNumber]);

  useEffect(() => {
    fetchStudent();
    // eslint-disable-next-line
  }, [studentNumber]);

  useEffect(() => {
    if (exam && data && student) {
      const pack = getCalculations(exam, data);
      setPack(pack);
    }
    // eslint-disable-next-line
  }, [exam, student]);

  const getCurrentTabComponent = () => {
    switch (currentTab) {
      case "exam":
        return ExamTab;
      default:
        return LessonTab;
    }
  };

  const CurrentTabComponent = getCurrentTabComponent();

  return (
    <DashboardTemplate
      title="Sonuçlar"
      bodyClass="no-padding"
      loading={!pack && errors.length === 0}
    >
      {errors.length > 0 && (
        <ErrorBox>
          {errors.map((error, index) => (
            <div key={index}>{error}</div>
          ))}
        </ErrorBox>
      )}
      {errors.length === 0 && (
        <VerticalTab
          currentTab={
            <CurrentTabComponent
              answers={answers}
              exam={exam}
              student={student}
              currentTab={currentTab}
              pack={pack}
            />
          }
        >
          {tabs.map((tab, index) => (
            <TabItem
              key={index}
              name={tab.name}
              active={currentTab === tab.name}
              onClick={({ name }) => setCurrentTab(name)}
            >
              {tab.title}
            </TabItem>
          ))}
        </VerticalTab>
      )}
    </DashboardTemplate>
  );
}
